<template>
  <div>
    <span
      class="text-base text-darkPurple font-semibold block py-2 cursor-pointer"
      v-for="(tab, i) in tabs"
      :key="i"
      :class="[
        currentTab === tabs[i] ? 'activeTab tw-text-primary' : 'inactiveTab',
      ]"
    >
      {{ tab }}
    </span>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: ["currentTab", "tabs"]
};
</script>

<style scoped>
.activeTab {
  border-right: 5px solid #ee5d4f;
}

.inactiveTab {
  color: #b8b7b8;
}
</style>